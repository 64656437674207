<template>
    <custom-table
        :title="$t('pages.module.lecture.streamSettings.title')"
        :cardConfig="{removePadding: true}"
        :subTitle="$t('pages.module.lecture.streamSettings.subTitle')"
        selectableRows
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow">
        <template v-slot:type="{ row: record }">
            {{ $t('pages.module.lecture.streamSettings.streamTypes.' + record.stream_type.toLowerCase()) }}
        </template>
        <template v-slot:date="{ row: record }">
            {{ record.stream_date ? $moment(record.stream_date).format("DD.MM.YYYY - HH:mm:ss") : "-" }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <a v-on:click="fetchStream(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg"/>
                    </span>
                </a>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_add_stream" ref="addStreamModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="streamForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.lecture.streamSettings.cols.type') }}</label>
                                <el-form-item prop="stream_type" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.stream_type" class="w-100" :placeholder="$t('common.chooseSelect')" :disabled="form.updateStatus" @change="selectedStreamType">
                                        <el-option v-for="(streamType, streamTypeIndex) in addableStreamTypes" :key="streamTypeIndex" :value="streamType" :label="$t('pages.module.lecture.streamSettings.streamTypes.' + streamType.toLowerCase())"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.lecture.streamSettings.cols.teacher') }}</label>
                                <el-form-item prop="teacher_id" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.teacher_id" class="w-100" :placeholder="$t('common.chooseSelect')" filterable>
                                        <el-option v-for="(teacher, teacherIndex) in teachers" :key="teacherIndex" :value="teacher.id" :label="teacher.firstname + ' ' + teacher.lastname">{{ teacher.firstname + ' ' + teacher.lastname }}</el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.lecture.streamSettings.cols.streamData.api') }}</label>
                                <el-form-item prop="stream_data.api" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.stream_data.api" class="w-100" :placeholder="$t('common.chooseSelect')" @change="selectedStreamApi">
                                        <el-option v-for="(streamApi, streamApiIndex) in streamApi" :key="streamApiIndex" :value="streamApi" :label="$t('pages.module.lecture.streamSettings.providers.' + streamApi, streamApi)"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7" v-if="['ADOBE_CONNECT', 'ADOBE_CONNECT_OLD'].includes(form.data.stream_data.api) || (form.data.stream_type !== 'LIVE' && form.data.stream_data.api === 'ZOOM')">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.lecture.streamSettings.cols.streamData.url') }}</label>
                                <el-form-item prop="stream_data.url" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.stream_data.url" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7" v-if="form.data.stream_type === 'LIVE' && form.data.stream_data.api === 'ZOOM'">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.lecture.streamSettings.cols.streamData.zoom') }}</label>
                                <el-form-item prop="stream_data.meeting_id" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.stream_data.meeting_id" class="w-100" :placeholder="$t('common.chooseSelect')" @change="selectedZoomMeeting">
                                        <el-option v-for="(zoomMeeting, zoomMeetingIndex) in zoomMeetings" :key="zoomMeetingIndex" :value="zoomMeeting.id" :label="sprintf('%s (%s)', [zoomMeeting.topic, ((zoomMeeting.start_date) ? $moment(zoomMeeting.start_date).format('DD.MM.YYYY HH:mm') : '-')])"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7" v-if="form.data.stream_type === 'LIVE' && ['ADOBE_CONNECT', 'ADOBE_CONNECT_OLD'].includes(form.data.stream_data.api)">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.lecture.streamSettings.cols.streamData.userGroup') }}</label>
                                <el-form-item prop="stream_data.group_id">
                                    <el-select
                                        v-model="form.data.stream_data.group_id"
                                        class="w-100"
                                        :placeholder="$t('common.chooseSelect')"
                                        filterable
                                        clearable
                                        remote
                                        :remote-method="searchAdobeConnectGroup"
                                        :loading="adobeConnectGroupSelect.loading">
                                        <el-option v-for="(group, groupIndex) in adobeConnectGroupSelect.list" :key="groupIndex" :value="group.id" :label="group.name"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7" v-if="form.data.stream_type === 'RECORD' && form.data.stream_data.provider_id">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.lecture.streamSettings.cols.streamData.accountId') }}</label>
                                <el-form-item prop="stream_data.account_id" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.stream_data.account_id" class="w-100" :placeholder="$t('common.chooseSelect')">
                                        <el-option v-for="(account, accountIndex) in stream.provider.accounts" :key="accountIndex" :value="account.id" :label="account.title"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <template v-if="['VIMEO', 'VISTREAM'].includes(form.data.stream_data.api)">
                                <div class="fv-row mb-7">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.lecture.streamSettings.cols.streamData.videoId') }}</label>
                                    <el-form-item prop="stream_data.video_id" :rules="$validation.getMessage(['required'])">
                                        <el-input v-model="form.data.stream_data.video_id" type="text"/>
                                    </el-form-item>
                                </div>

                                <!--<div class="fv-row mb-7">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.lecture.streamSettings.cols.streamData.server') }}</label>
                                    <el-form-item prop="stream_data.server">
                                        <el-input v-model="form.data.stream_data.server" type="text"/>
                                    </el-form-item>
                                </div>-->
                            </template>

                            <div class="fv-row mb-1" v-if="form.data.stream_data.api">
                                <label class="fs-6 fw-bold mb-2" :class="form.data.stream_type === 'LIVE' && 'required'">{{ $t('pages.module.lecture.streamSettings.cols.' + (form.data.stream_type === 'LIVE' ? 'activityDate' : 'date')) }}</label>
                                <el-form-item prop="stream_date">
                                    <el-date-picker
                                        class="rangeDateTimePicker"
                                        v-model="form.data.stream_date"
                                        type="datetime"
                                        popper-class="rangeDateTimePickerPopper"
                                        :placeholder="$t('common.chooseDateAndTime')"
                                        valueFormat="YYYY-MM-DD HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.lecture.streamSettings.cols.image') }}</label>
                                <el-form-item prop="image_id">
                                    <el-upload :accept="$root.acceptFileSupported('image', 'image/')" v-bind:class="{'disabled-upload': image.fileList.length > 0}" :on-change="handleChangeImage" :on-remove="handleChangeImage" list-type="picture-card"
                                               :file-list="image.fileList" limit="1" :on-preview="handlePictureCardPreview" :auto-upload="false">
                                        <i class="bi bi-plus"/>
                                    </el-upload>
                                    <el-dialog v-model="image.dialogVisible" width="30%">
                                        <img style="width: 100%" :src="image.dialogImageUrl" alt=""/>
                                    </el-dialog>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    props: ['lectureID', 'supportedStreamVariants'],
    name: "Stream",
    components: {
        CustomTable
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("pages.module.lecture.streamSettings.cols.id"),
                    key: "id"
                },
                {
                    name: this.$t("pages.module.lecture.streamSettings.cols.type"),
                    scopedSlots: {customRender: "type"}
                },
                {
                    name: this.$t("pages.module.lecture.streamSettings.cols.date"),
                    scopedSlots: {customRender: "date"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {
                    stream_data: {}
                }
            },
            selectedRowKeys: [],
            table: {
                data: [],
                loading: false
            },
            image: {
                dialogImageUrl: '',
                dialogVisible: false,
                uploadList: [],
                fileList: []
            },
            responseMessages: {
                same_stream_type_already_exists: "sameStreamTypeAlreadyExists",
                video_id_invalid: "videoIdInvalid"
            },
            supportedStreamTypes: {
                LIVE: ['LIVE', 'RECORD', 'LIVE_RECORD'],
                RECORD: ['RECORD'],
                VIDEO: ['RECORD']
            },
            adobeConnectGroupSelect: {
                list: [],
                loading: false
            },
            stream: {
                provider: {
                    accounts: []
                }
            }
        }
    },
    computed: {
        teachers() {
            return this.$store.state.module.teacher.table.data;
        },
        actions() {
            let actions = [
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ];

            if (this.table.data.length != this.addableStreamTypes.length) {
                actions.unshift({
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                });
            }

            return actions;
        },
        addableStreamTypes() {
            let streamTypes = [];

            this.supportedStreamVariants.forEach((streamVariantCode) => {
                let streamVariant = this.supportedStreamTypes[streamVariantCode];

                streamVariant.forEach((streamType) => {
                    if (!streamTypes.includes(streamType)) {
                        streamTypes.push(streamType);
                    }
                });
            })

            return streamTypes;
        },
        streamApi() {
            let streamApi = [];

            if (this.form.data.stream_type === 'RECORD') {
                /*['VIMEO', 'VISTREAM'].forEach((provider) => {
                    streamApi.push(provider);
                });*/
                streamApi = Object.keys(this.streamProvidersObj);
            } else {
                ['ADOBE_CONNECT', 'ADOBE_CONNECT_OLD', 'ZOOM'].forEach((provider) => {
                    streamApi.push(provider);
                })
            }

            return streamApi;
        },
        adobeConnectGroups() {
            return this.$store.state.module.adobeConnect.group.table.data;
        },
        adobeConnect2Groups() {
            return this.$store.state.module.adobeConnect2.group.table.data;
        },
        zoomMeetings() {
            return this.$store.state.module.zoom.meeting.table.data;
        },
        streamProviders() {
            return this.$store.state.stream.provider.table.data;
        },
        streamProvidersObj() {
            let obj = {};

            this.streamProviders.forEach((provider) => {
                obj[provider.code.toUpperCase()] = provider;
            });

            return obj;
        }
    },
    mounted() {
        this.loadLecture();
        this.$store.dispatch('module/teacher/get', {
            page: {pageSize: 9999}
        });
        this.$store.dispatch('module/adobeConnect/group/get');
        this.$store.dispatch('module/adobeConnect2/group/get');

        this.$store.dispatch('module/zoom/meeting/get', {
            page: {pageSize: 9999},
            filterData: {
                meeting_statuses: ['not_started', 'started'],
                sort: 'id:desc'
            }
        });

        this.$store.dispatch('stream/provider/get', {
            page: {pageSize: 9999},
            filterData: {
                sort: 'created_at:desc',
                platform: ['private']
            }
        });
    },
    methods: {
        loadLecture() {
            this.table.loading = true;

            this.axios.get(this.endpoints['module_lecture'] + '/' + this.lectureID).then((response) => {
                let data = response.data.data;
                this.table.data = data.streams;
            }).finally(() => {
                this.table.loading = false;
            });
        },
        newStream() {
            this.form.updateStatus = false;
            this.resetImageData();
            this.form.data = {
                lecture_id: this.lectureID,
                stream_data: {}
            };
            this.form.title = this.$t("pages.module.lecture.streamSettings.newStream");
            this.showModal(this.$refs.addStreamModal);
        },
        fetchStream(record) {
            this.form.updateStatus = true;
            this.resetImageData();
            this.form.title = this.$t("pages.module.lecture.streamSettings.editStream");
            this.axios.get(this.endpoints['module_lecture_stream'] + '/' + record.id).then(response => {
                let data = response.data.data;

                this.form.data = data;

                if (data.stream_data.group_id) {
                    this.searchAdobeConnectGroup(data.stream_data.group_id, 'id')
                }

                if(data.stream_data.provider_id) {
                    this.loadStreamProviderAccounts(data.stream_data.provider_id);
                }

                if (data.image) {
                    this.image.fileList = [
                        {
                            name: data.image.name,
                            url: data.image.public_url
                        }
                    ]
                }

                this.showModal(this.$refs.addStreamModal);
            });
        },
        onSubmit() {
            if (this.form.data.stream_type === 'LIVE' && !this.form.data.stream_date) {
                this.$notify({
                    type: 'warning',
                    title: this.$t("messages.warning"),
                    message: this.$t("pages.module.lecture.streamSettings.requiredActivityDate"),
                })

                return;
            }

            this.$refs.streamForm.validate((valid) => {
                if (valid) {
                    this.form.loading = true;

                    if (this.image.uploadList.length > 0) {
                        this.submitFormWithUpload();
                    } else {
                        if (!(this.image.fileList.length > 0)) {
                            this.form.data.image_id = null;
                        }
                        this.submitForm();
                    }
                } else {
                    return false;
                }
            });
        },
        submitFormWithUpload() {
            const formData = new FormData();

            this.image.uploadList.forEach(file => {
                formData.append("file[]", file.raw);
            });

            this.axios.post(this.endpoints["file"], formData).then(response => {
                this.onResponse(response.data, () => {
                    let data = response.data.data;
                    this.form.data.image_id = data[0].id;
                    this.submitForm();
                }, () => {
                    this.form.loading = false;
                }, false);
            }).catch(error => {
                this.onResponseFailure(error.response.data, () => {
                    this.form.loading = false;
                });
            });
        },
        submitForm() {
            if (this.form.data.stream_data.api === 'VIMEO') {
                this.form.data.stream_data.server = 1;
            }

            if (this.form.data.id) {
                this.axios.put(this.endpoints['module_lecture_stream'] + '/' + this.form.data.id, this.form.data).then(response => {
                    this.onResponse(response.data, () => {
                        this.loadLecture();
                        this.hideModal(this.$refs.addStreamModal);
                    }, () => {
                        this.form.loading = false;
                    });
                }).catch(error => {
                    if (this.responseMessages[error.response.data.message]) {
                        error.response.data.message = this.$t('pages.module.lecture.streamSettings.responseMessages.' + this.responseMessages[error.response.data.message]);
                    }

                    this.onResponseFailure(error.response.data, () => {
                        this.form.loading = false;
                    });
                });
            } else {
                this.axios.post(this.endpoints['module_lecture_stream'], this.form.data).then(response => {
                    this.onResponse(response.data, () => {
                        this.loadLecture();
                        this.hideModal(this.$refs.addStreamModal);
                    }, () => {
                        this.form.loading = false;
                    });
                }).catch(error => {
                    if (this.responseMessages[error.response.data.message]) {
                        error.response.data.message = this.$t('pages.module.lecture.streamSettings.responseMessages.' + this.responseMessages[error.response.data.message]);
                    }

                    this.onResponseFailure(error.response.data, () => {
                        this.form.loading = false;
                    });
                });
            }
        },
        deleteRecord(id) {
            this.$store.dispatch("module/lecture/stream/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
                if (successDeleted) {
                    this.loadLecture();
                }
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.loadLecture();
                    break;

                case "new":
                    this.newStream();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        handleSelectedRow(record) {
            this.selectedRowKeys = record;
        },
        resetImageData() {
            this.image = {
                dialogImageUrl: '',
                dialogVisible: false,
                uploadList: [],
                fileList: []
            }
        },
        handlePictureCardPreview(file) {
            this.image.dialogImageUrl = file.url
            this.image.dialogVisible = true
        },
        handleChangeImage(file, fileList) {
            this.image.fileList = fileList;
            this.image.uploadList = fileList;
        },
        selectedStreamType() {
            this.form.data.stream_data = {};
        },
        selectedStreamApi() {
            let formData = this.form.data;
            let streamData = {
                api: formData.stream_data.api
            }

            if(this.streamProvidersObj[formData.stream_data.api] && formData.stream_type === 'RECORD') {
                streamData.provider_id = this.streamProvidersObj[formData.stream_data.api].id;
                this.loadStreamProviderAccounts(streamData.provider_id);
            }

            this.form.data.stream_data = streamData;
            this.adobeConnectGroupSelect.list = [];
        },
        searchAdobeConnectGroup(query, searchKey = 'name') {
            if (query) {
                this.adobeConnectGroupSelect.loading = true;
                let filteredList = [];

                if (this.form.data.stream_data.api == 'ADOBE_CONNECT') {
                    filteredList = this.adobeConnectGroups.filter((group) => {
                        return group[searchKey].toLocaleLowerCase('tr-TR').indexOf(query.toLocaleLowerCase('tr-TR')) > -1
                    });
                } else if (this.form.data.stream_data.api == 'ADOBE_CONNECT_OLD') {
                    filteredList = this.adobeConnect2Groups.filter((group) => {
                        return group[searchKey].toLocaleLowerCase('tr-TR').indexOf(query.toLocaleLowerCase('tr-TR')) > -1
                    });
                }

                this.adobeConnectGroupSelect.list = filteredList;

                setTimeout(() => {
                    this.adobeConnectGroupSelect.loading = false;
                }, 0);
            } else {
                this.adobeConnectGroupSelect.list = [];
            }
        },
        selectedZoomMeeting() {
            if (this.form.data.stream_data.meeting_id) {
                let meeting = this.zoomMeetings.filter((meeting) => meeting.id == this.form.data.stream_data.meeting_id);
                if (meeting.length == 1) {
                    this.form.data.stream_date = this.$moment(meeting[0].start_date).format('YYYY-MM-DD HH:mm:ss');
                }
            }
        },
        loadStreamProviderAccounts(providerId){
            this.axios.get(this.endpoints['stream_provider_account'], {
                params: {
                    provider_id: providerId,
                    sort: "created_at:desc"
                }
            }).then(response => {
                let data = response.data.data;
                this.stream.provider.accounts = data;
            });
        }
    }
}
</script>

<style>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}
</style>