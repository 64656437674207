<template>
    <custom-table
        :title="$t('pages.ecommerce.user.label.creator.title')"
        :subTitle="$t('pages.ecommerce.user.label.creator.subTitle')"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        @action="handleClickAction"
        @changeTable="handleTableChange">

        <template v-slot:mode="{ row: record }">
            <span :class="modes[record.payload.mode].badge" class="badge">{{ $t('pages.ecommerce.user.label.creator.modes.' + modes[record.payload.mode].code) }}</span>
        </template>

        <template v-slot:state="{ row: record }">
            <span :class="status[record.status_id].badge" class="badge">{{ $t('pages.ecommerce.user.label.creator.status.' + status[record.status_id].code) }}</span>
        </template>

        <template v-slot:completedAt="{ row: record }">
            {{ record.completed_at ? $moment(record.completed_at).format("DD.MM.YYYY - HH:mm:ss") : "-" }}
        </template>

        <template v-slot:createdAt="{ row: record }">
            {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
        </template>

        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <a v-on:click="detailRecord(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/general/gen004.svg"/>
                    </span>
                </a>
                <a v-on:click="exportRecord(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm" v-if="record.status_id == 3">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/files/fil021.svg"/>
                    </span>
                </a>
            </div>
        </template>
    </custom-table>

    <div class="modal fade" id="kt_modal_export_filter" ref="exportFilterModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_export_filter_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_export_filter_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onExportFilter()" :model="form.export.filter" ref="exportFilterForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_export_filter_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-10 p-6">
                                <span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                    <inline-svg src="/media/icons/duotune/general/gen044.svg"/>
                                </span>
                                <div class="d-flex flex-stack flex-grow-1">
                                    <div class="fw-bold">
                                        <div class="fs-6 text-gray-600">{{ $t('pages.ecommerce.user.label.creator.messages.export') }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('common.status') }}</label>
                                <el-form-item prop="status_ids">
                                    <el-select v-model="form.export.filter.status_ids" class="w-100" :placeholder="$t('common.chooseSelect')" clearable multiple>
                                        <el-option v-for="(state, stateIndex) in exportStatus" :key="stateIndex" :value="(stateIndex + 1)" :label="$t('pages.ecommerce.user.label.creator.exportStatus.' + state)"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("common.export") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
    <div class="modal fade" id="kt_modal_detail" ref="detailModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_reservation_detail_header">
                    <h2 class="fw-bolder">{{ $t('pages.ecommerce.user.label.creator.detail.title') }}</h2>
                    <div id="kt_modal_reservation_detail_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <div class="modal-body py-10 px-lg-17" v-if="form.detail.payload && form.detail.payload.items && form.detail.payload.items.length">
                    <div class="scroll-y me-n7 pe-7" id="kt_modal_reservation_detail_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                        <div class="fv-row mb-3">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.user.label.creator.detail.cols.mode') }}:</label>
                            <span class="text-gray-500 fw-bold fs-6">{{ $t('pages.ecommerce.user.label.creator.modes.' + modes[form.detail.payload.mode].code) }}</span>
                        </div>

                        <div class="fv-row mb-3" v-if="form.detail.labels.length">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.user.label.creator.detail.cols.selectedLabels') }}:</label>
                            <span class="text-gray-500 fw-bold fs-6">{{ form.detail.labels.map(i => i.name).join(', ') }}</span>
                        </div>
                    </div>
                </div>

                <div class="modal-footer flex-center">
                    <button class="btn btn-lg btn-primary" type="button" v-on:click="hideModal($refs.detailModal)">{{ $t("btn.close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "index",
    components: {
        CustomTable
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.ecommerce.user.label.creator.cols.creator"),
                    key: "admin.full_name"
                },
                {
                    name: this.$t("pages.ecommerce.user.label.creator.cols.mode"),
                    scopedSlots: {customRender: "mode"}
                },
                {
                    name: this.$t("pages.ecommerce.user.label.creator.cols.labelCount"),
                    key: "payload.items.length"
                },
                {
                    name: this.$t("pages.ecommerce.user.label.creator.cols.totalUser"),
                    key: "total"
                },
                {
                    name: this.$t("pages.ecommerce.user.label.creator.cols.success"),
                    key: "success"
                },
                {
                    name: this.$t('common.status'),
                    scopedSlots: {customRender: "state"}
                },
                {
                    name: this.$t("pages.ecommerce.user.label.creator.cols.completedAt"),
                    scopedSlots: {customRender: "completedAt"}
                },
                {
                    name: this.$t("common.createdAtShort"),
                    scopedSlots: {customRender: "createdAt"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                detail: {},
                export: {
                    filter: {}
                },
                data: {}
            },
            modes: {
                add: {
                    code: 'add',
                    badge: 'badge-success'
                },
                remove: {
                    code: 'remove',
                    badge: 'badge-danger'
                }
            },
            status: {
                1: {
                    code: 'pending',
                    badge: 'badge-primary'
                },
                2: {
                    code: 'processing',
                    badge: 'badge-info'
                },
                3: {
                    code: 'completed',
                    badge: 'badge-success'
                }
            },
            exportStatus: ['pending', 'processed', 'error']
        }
    },
    computed: {
        table() {
            return this.$store.state.ecommerce.user.label.creator.table;
        }
    },
    mounted() {
        this.setCurrentPageBreadcrumbs(this.$t("menu.userLabelCreator"), [this.$t("menu.ecommerceManagement"), this.$t("menu.userManagement")]);

        this.$store.dispatch('ecommerce/user/label/creator/get', {
            page: {},
            filterData: {
                sort: 'id:desc'
            }
        });
    },
    methods: {
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.$router.push({
                        path: "/ecommerce/user/label/creator/save"
                    });
                    break;

                default:
                    break;
            }
        },
        refreshTable() {
            this.$store.dispatch("ecommerce/user/label/creator/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("ecommerce/user/label/creator/get", {
                page: pagination,
                filterData: Object.assign({
                    sort: 'id:desc',
                }, filterData)
            });
        },
        detailRecord(record) {
            this.form.detail.payload = record.payload;
            this.form.detail.labels = {};

            this.axios.get(this.endpoints['user_label'], {
                params: {
                    ids: record.payload.items
                }
            }).then(response => {
                this.form.detail.labels = response.data.data;
                this.showModal(this.$refs.detailModal);
            });
        },
        exportRecord(record) {
            this.form.updateStatus = false;

            this.form.export = {
                id: record.id,
                filter: {}
            };

            this.form.title = this.$t("pages.ecommerce.user.label.creator.exportFilter");
            this.showModal(this.$refs.exportFilterModal);
        },
        onExportFilter() {
            this.form.loading = true;

            this.axios({
                url: this.sprintf('%s/%d', [this.endpoints['user_label_creator_export'], this.form.export.id]),
                method: 'get',
                responseType: "blob",
                params: this.form.export.filter
            }).then(response => {
                const type = response.headers['content-type']
                const blob = new Blob([response.data], {type: type, encoding: 'UTF-8'})
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.click();

                this.hideModal(this.$refs.exportFilterModal);
            }).finally(() => {
                this.form.loading = false;
            });
        }
    }
}
</script>