<template>
    <input
        ref="mobilePhoneInput"
        id="mobile_phone_selector"
        type="text"
        name="mobile_phone"
        inputmode="tel"
        class="el-input__inner"
        autocomplete="nope"
        v-model="mobilePhone"
        placeholder="535 042 31 33"
        required
        @input="handleInputChange"
    >
</template>

<script>
import { PhoneNumberUtil } from "google-libphonenumber";
export default {
    props : {
        data : String,
    },
    data() {
        return {
            mobilePhone: '',
            iti: null,
        };
    },
    mounted() {
        this.initIntlTelInput();
    },
    methods: {
        initIntlTelInput() {
            const input = this.$refs.mobilePhoneInput;
            let countryCode = this.getRegion( this.data ) ?? 'TR';
            this.iti = window.intlTelInput(input, {
                initialCountry: countryCode,
                autoPlaceholder: "aggressive",
                separateDialCode: true,
                loadUtils: () => import("@/assets/js/_utils/IntlTelInputUtils"),
                i18n: {
                  tr: 'Türkiye (Turkey)',
                  searchPlaceholder: this.$t('customTable.customPhoneInput'),
                },
            });
            if ( this.data ) {
                const countryDialCode = this.iti.getSelectedCountryData().dialCode;
                this.mobilePhone = this.data.replace(/\D/g, "").replace(new RegExp(`^${countryDialCode}`), "");
            }

            input.addEventListener('countrychange', () => {
                this.handleCountryChange();
            });
        },
        handleCountryChange() {
            const input = this.$refs.mobilePhoneInput;
            const countryDialCode = this.iti.getSelectedCountryData().dialCode;
            const countryContainerWidth = input.closest('.iti').querySelector('.iti__selected-country').offsetWidth;
            input.style.setProperty('--dynamic-padding', `${countryContainerWidth + 16}px`);
            input.classList.add('dynamic-padding');
            this.mobilePhone = '';
            this.$emit("mobile-phone-number", {
                number : '+' + countryDialCode ,
                isValid : false
            });
        },
        handleInputChange() {
            const input = this.$refs.mobilePhoneInput;
            const countryDialCode = this.iti.getSelectedCountryData().dialCode;
            const countryCode = this.iti.getSelectedCountryData().iso2.toUpperCase();
            let inputValue = input.value.replace(/\D/g, "");
            if (inputValue.startsWith(countryDialCode)) {
                inputValue = inputValue.replace(new RegExp(`^${countryDialCode}`), "");
            }
            let index = 0;
            this.mobilePhone = input.placeholder.replace(/[0-9]/g, '#').replace(/#/g, () => inputValue[index++] || "").trim();

            this.$emit("mobile-phone-number", {
                number : '+' +countryDialCode + this.mobilePhone.replace(/\D/g, ""),
                isValid : this.validatePhoneNumber( this.mobilePhone, countryCode )
            } );
        },
        getRegion( number ) {
            const phoneUtil = PhoneNumberUtil.getInstance();
            try {
                const parsedNumber = phoneUtil.parse(number);
                return phoneUtil.getRegionCodeForNumber(parsedNumber) ?? null;
            } catch (e) {
                return null;
            }
        },
        validatePhoneNumber( number, country ) {
            const phoneUtil = PhoneNumberUtil.getInstance();
            try {
                const phone = phoneUtil.parseAndKeepRawInput( number , country);
                return phone && phoneUtil.isValidNumber(phone);
            } catch (e) {
                return false;
            }
        },
    },
    watch: {
        data(newVal) {
            if (newVal) {
                const input = this.$refs.mobilePhoneInput;
                const countryDial = this.iti.getSelectedCountryData().iso2.toUpperCase();
                if (this.iti && countryDial !== this.getRegion( newVal ) && this.getRegion( newVal ) ) {
                    this.iti.destroy();
                    let countryCode = this.getRegion( this.data ) ?? 'TR';
                    this.iti = window.intlTelInput(input, {
                        initialCountry: countryCode,
                        autoPlaceholder: "aggressive",
                        separateDialCode: true,
                        loadUtils: () => import("@/assets/js/_utils/IntlTelInputUtils"),
                        i18n: {
                            tr: 'Türkiye (Turkey)',
                            searchPlaceholder: this.$t('customTable.customPhoneInput'),
                        },
                    });
                }
                const countryDialCode = this.iti.getSelectedCountryData().dialCode;
                newVal = newVal.replace(/\D/g, "").replace(new RegExp(`^${countryDialCode}`), "");
                let inputValue = newVal.replace(/\D/g, "");
                let index = 0;
                this.mobilePhone = input.placeholder.replace(/[0-9]/g, '#').replace(/#/g, () => inputValue[index++] || "").trim();
            }
        },
    },
};
</script>

<style>
.iti {
    width: 100%;
}
.iti__country-container .iti__selected-country {
    background-color: #f3f3f4;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-right: 4px;
}
.iti__selected-country-primary {
    padding-right: 0;
}
.iti__country-container .iti__selected-country .iti__selected-dial-code {
    font-size: 12px;
}
.iti--inline-dropdown .iti__dropdown-content {
    margin-top: 8px;
    border-radius: 16px;
    border: 1px solid #f3f3f4;
}
.iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])) .iti__selected-country-primary:hover, .iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])) .iti__selected-country:has(+ .iti__dropdown-content:hover) .iti__selected-country-primary {
    background: transparent;
}
.iti__search-input {
    padding: 4px 8px;
    border-radius: 16px;
}
.iti__search-input:focus-visible {
    outline: none;
}
.iti__country {
    padding: 0 8px;
}
</style>
