<template>
    <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true">
        <div class="px-7 py-5">
            <div class="fs-5 text-dark fw-bolder">{{ $t('filters.title') }}</div>
        </div>

        <div class="separator border-gray-200"></div>

        <div class="px-7 py-5 overflow-auto max-h-300px">
            <div class="mb-10" v-if="!userID">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.user.id') }}:</label>
                <div>
                    <el-input v-model="filterData.id" oninput="value=value.replace(/[^0-9.]/g,'')"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.user.firstname') }}:</label>
                <div>
                    <el-input v-model="filterData.firstname" type="text"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.user.lastname') }}:</label>
                <div>
                    <el-input v-model="filterData.lastname" type="text"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.user.email') }}:</label>
                <div>
                    <el-input v-model="filterData.email" type="text"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.user.identificationNumber') }}:</label>
                <div>
                    <el-input v-model="filterData.identification_number" v-mask="{ mask:'99999999999', placeholder: '' }" type="text"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.user.mobilePhone') }}:</label>
                <div>
                    <el-input v-model="filterData.mobile_phone" type="text"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.user.birthDate') }}:</label>
                <div>
                    <el-date-picker v-model="filterData.birth_date" type="date" :placeholder="$t('common.chooseDate')" valueFormat="YYYY-MM-DD" :disabledDate="disabledDate"></el-date-picker>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.user.isAbroad') }}:</label>
                <div>
                    <el-select v-model="filterData.is_abroad" class="w-100" :placeholder="$t('common.chooseSelect')" clearable>
                        <el-option :value="true" :label="$t('common.yes')"></el-option>
                        <el-option :value="false" :label="$t('common.no')"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="mb-10">
                <label class="fs-6 fw-bold mb-2">{{ $t('filters.ecommerce.user.label') }}</label>
                <el-select v-model="filterData.labels" class="w-100" :placeholder="$t('common.chooseSelect')" filterable multiple>
                    <el-option v-for="(label, labelIndex) in labels" :key="labelIndex" :value="label.id" :label="label.name"></el-option>
                </el-select>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.user.createdAtMin') }}:</label>
                <div>
                    <el-date-picker class="w-100" v-model="filterData['created_at[min]']" type="date" :placeholder="$t('common.chooseDate')" valueFormat="YYYY-MM-DD" :disabledDate="disabledCreatedMinDate" clearable></el-date-picker>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.user.createdAtMax') }}:</label>
                <div>
                    <el-date-picker class="w-100" v-model="filterData['created_at[max]']" type="date" :placeholder="$t('common.chooseDate')" valueFormat="YYYY-MM-DD" :disabledDate="disabledCreatedMaxDate" clearable></el-date-picker>
                </div>
            </div>
            <div class="mb-10" v-for="(element, elementIndex) in registerElements" :key="elementIndex">
                <label class="form-label fw-bold">{{ element.translate.title }}:</label>
                <div>
                    <el-input v-if="element.type.code == 'input'" v-model="filterData[element.input_name]" type="text"/>
                    <el-input v-if="element.type.code == 'textarea'" v-model="filterData[element.input_name]" type="textarea" rows="3" maxlength="512"/>
                    <el-select v-if="element.type.code == 'select'" v-model="filterData[element.input_name]" class="w-100" :placeholder="$t('common.chooseSelect')" filterable clearable :multiple="element.options && element.options.multiple">
                        <el-option v-for="(item, itemIndex) in element.translate.items" :key="itemIndex" :value="item.val" :label="item.text"></el-option>
                    </el-select>
                    <el-select v-if="element.type.code == 'state'" v-model="filterData[element.input_name]" class="w-100" filterable @change="stateChanged(element.input_name)" :placeholder="$t('common.chooseSelect')" clearable :multiple="element.options && element.options.multiple">
                        <el-option v-for="(state, stateIndex) in stateWithCities" :key="stateIndex" :label="state.name" :value="state.name"></el-option>
                    </el-select>
                    <el-select v-if="element.type.code == 'city'" v-model="filterData[element.input_name]" class="w-100 citySelect" filterable :placeholder="$t('common.chooseSelect')" clearable :multiple="element.options && element.options.multiple" :data-inputName="element.input_name">
                        <el-option v-for="(city, cityIndex) in cities" :key="cityIndex" :label="city.name" :value="city.name"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.user.status') }}:</label>
                <div>
                    <el-select v-model="filterData.active" class="w-100" :placeholder="$t('common.chooseSelect')" clearable>
                        <el-option :value="true" :label="$t('common.active')"></el-option>
                        <el-option :value="false" :label="$t('common.passive')"></el-option>
                    </el-select>
                </div>
            </div>
        </div>

        <div class="separator border-gray-200"></div>

        <div class="px-7 py-5">
            <div class="d-flex justify-content-end">
                <button type="reset" v-on:click="onReset" class="btn btn-sm btn-white btn-active-light-primary me-2" data-kt-menu-dismiss="true">{{ $t('btn.reset') }}</button>
                <button type="submit" v-on:click="onFilter" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">{{ $t('btn.apply') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    data() {
        return {
            filterData: {},
            cities: []
        }
    },
    computed: {
        userID() {
            return this.$route.query.userID;
        },
        registerElements() {
            return this.$store.state.system.setting.registerElement.table.data.map((element) => {
                element.translate = this.resolveDatum(element.translations, this.$root.defaultLanguage.id, 'language_id');
                return element;
            });
        },
        stateWithCities() {
            return this.$store.state.localization.country.state.table.data;
        },
        labels() {
            return this.$store.state.ecommerce.user.label.table.data;
        },
    },
    mounted() {
        if (this.userID) {
            this.filterData.id = this.userID;
        }

        this.$store.dispatch('system/setting/registerElement/get', {
            page: {pageSize: 9999}
        });

        this.$store.dispatch('localization/country/state/get', {
            page: {pageSize: 9999},
            filterData: {
                country_id: 1
            }
        });

        this.$store.dispatch('ecommerce/user/label/get', {
            page: {
                pageSize: 9999
            }
        });
    },
    methods: {
        onReset() {
            this.filterData = {};
            this.onFilter();
        },
        onFilter() {
            this.$emit('onFilter', this.filterData);
        },
        disabledDate(date) {
            return date.getTime() > Date.now()
        },
        disabledCreatedMinDate(startDate) {
            startDate = this.$moment(startDate);
            const endDate = this.filterData['created_at[max]'] ? this.$moment(this.filterData['created_at[max]']) : undefined;

            return endDate == undefined ? false : startDate.valueOf() > endDate.valueOf()
        },
        disabledCreatedMaxDate(endDate) {
            endDate = this.$moment(endDate);
            const startDate = this.filterData['created_at[min]'] ? this.$moment(this.filterData['created_at[min]']) : undefined;

            return startDate == undefined ? false : startDate.valueOf() > endDate.valueOf();
        },
        stateChanged(stateInputName) {
            this.cities = [];
            let cities = document.querySelectorAll(".citySelect");
            cities.forEach((cityElement) => {
                let inputName = cityElement.getAttribute('data-inputName');
                delete this.filterData[inputName];
            })

            if (this.filterData[stateInputName]) {
                let foundIndex = this.stateWithCities.findIndex(state => state.name == this.filterData[stateInputName]);

                if(foundIndex >= 0) {
                    this.cities = this.cloneData(this.stateWithCities[foundIndex].cities);
                }
            }
        }
    }
}
</script>

<style>

</style>