<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            {{ $t('pages.ecommerce.user.label.creator.save.new') }}
        </h2>
        <router-link to="/ecommerce/user/label/creator" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
            </span>
            {{ $t("menu.userLabelCreator") }}
        </router-link>
    </div>

    <el-form :model="form.userCheck.data" ref="userCheckForm">
        <div class="card mb-7">
            <div class="card-body d-flex flex-column p-9">
                <template v-if="!form.userCheck.checked">
                    <div class="fv-row mb-1">
                        <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.label.creator.save.cols.user') }}</label>
                        <el-form-item prop="items" :rules="$validation.getMessage(['required'])">
                            <el-input v-model="form.userCheck.data.items" type="textarea" rows="10"/>
                        </el-form-item>
                    </div>
                    <div class="fv-row mt-2">
                        <el-form-item class="mb-0">
                            <button @click.prevent="onUserCheck" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="button">
                                <span v-if="!form.loading" class="indicator-label">{{ $t("btn.check") }}</span>
                                <span v-if="form.loading" class="indicator-progress">
                                    {{ $t("messages.wait") }}
                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </el-form-item>
                    </div>
                </template>

                <div class="fv-row mt-5 text-center" v-if="Object.keys(form.userCheck.result).length && form.userCheck.checked">
                    <label class="fs-4 fw-bold mb-2">{{ $t('pages.ecommerce.user.label.creator.save.userCheck.title') }}</label>
                    <div class="row justify-content-center px-9 mt-5">
                        <div class="col-6 col-md-4 text-center mb-2 mb-xs-0">
                            <div class="text-gray-800 fw-bold fs-3">
                                <span>{{ form.userCheck.result.count.total }}</span>
                            </div>

                            <span class="text-gray-700 fs-7 d-block fw-bold">{{ $t('pages.ecommerce.user.label.creator.save.userCheck.count.total') }}</span>
                        </div>

                        <div class="col-6 col-md-4 text-center mb-2 mb-xs-0">
                            <div class="text-gray-800 fw-bold fs-3">
                                <span>{{ form.userCheck.result.count.available }}</span>
                            </div>

                            <span class="text-gray-700 fs-7 d-block fw-bold">{{ $t('pages.ecommerce.user.label.creator.save.userCheck.count.available') }}</span>
                        </div>

                        <div class="col-6 col-md-4 text-center mb-2 mb-xs-0">
                            <div class="text-gray-800 fw-bold fs-3">
                                <span>{{ form.userCheck.result.count.unavailable }}</span>
                            </div>

                            <span class="text-gray-700 fs-7 d-block fw-bold">{{ $t('pages.ecommerce.user.label.creator.save.userCheck.count.unavailable') }}</span>
                        </div>
                    </div>

                    <div class="mt-12">
                        <button class="btn btn-lg btn-primary" type="button" v-on:click="form.userCheck.checked = false">{{ $t("pages.ecommerce.user.label.creator.save.userCheck.retryCheck") }}</button>
                    </div>
                </div>
            </div>
        </div>
    </el-form>

    <el-form :model="form.data" ref="labelForm" v-if="form.data.users.length > 0">
        <div class="row g-6 mb-6 g-xl-9 mb-xl-9">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-body d-flex flex-column p-9">
                        <div class="fv-row mb-1">
                            <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.label.creator.save.cols.chooseLabel') }}</label>
                            <el-form-item prop="items" :rules="$validation.getMessage(['required'])">
                                <el-select v-model="form.data.items" class="w-100" :placeholder="$t('common.chooseSelect')" filterable multiple>
                                    <el-option v-for="(label, labelIndex) in userLabels" :key="labelIndex" :value="label.id" :label="label.name">{{ label.name }}</el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="card">
                    <div class="card-body d-flex flex-column p-9">
                        <div class="fv-row mb-1">
                            <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.label.creator.save.cols.mode') }}</label>
                            <el-form-item prop="mode" :rules="$validation.getMessage(['required'])">
                                <el-select v-model="form.data.mode" class="w-100" :placeholder="$t('common.chooseSelect')">
                                    <el-option v-for="(mode, modeIndex) in modes" :key="modeIndex" :value="mode" :label="$t('pages.ecommerce.user.label.creator.modes.' + mode) ">{{ $t('pages.ecommerce.user.label.creator.modes.' + mode) }}</el-option>
                                </el-select>
                            </el-form-item>
                        </div>

                        <div class="fv-row mt-5">
                            <el-form-item class="mb-0">
                                <button @click.prevent="onSubmit" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary w-100" type="button">
                                    <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                                    <span v-if="form.loading" class="indicator-progress">
                                        {{ $t("messages.wait") }}
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
export default {
    name: "index",
    data() {
        return {
            form: {
                loading: false,
                userCheck: {
                    checked: false,
                    result: {},
                    data: {}
                },
                data: {
                    mode: undefined,
                    users: [],
                    items: []
                }
            },
            modes: ['add', 'remove']
        }
    },
    computed: {
        userLabels() {
            return this.$store.state.ecommerce.user.label.table.data;
        }
    },
    mounted() {
        this.$store.dispatch('ecommerce/user/label/get', {
            page: {pageSize: 9999}
        });
    },
    methods: {
        onUserCheck() {
            this.$refs.userCheckForm.validate((valid) => {
                if (valid) {
                    let formData = this.prepareUserCheckFormData();
                    this.form.loading = true;

                    this.axios.post(this.sprintf('%s?include[]=show_detail', [this.endpoints['user_check']]), formData).then(response => {
                        this.onResponse(response.data, () => {
                            let data = response.data.data;

                            if (data.items && data.items.unavailable && data.items.unavailable.length) {
                                let blob = new Blob([data.items.unavailable.join('\n')], {
                                    type: "text/plain;charset=utf-8;",
                                });

                                this.trustedWindowOpen(URL.createObjectURL(blob), undefined, 'unavailable_users')
                            }

                            if (data.items && data.items.available && data.items.available.id && Array.isArray(data.items.available.id) && data.items.available.id.length) {
                                this.form.data.users = data.items.available.id;
                            }

                            this.form.userCheck.result = data;
                            this.form.userCheck.checked = true;
                        }, () => {
                            this.form.loading = false;
                        }, false);
                    }).catch(error => {
                        this.onResponseFailure(error.response.data, () => {
                            this.form.loading = false;
                        });
                    });
                } else {
                    return false;
                }
            });
        },
        prepareUserCheckFormData() {
            let formData = this.cloneData(this.form.userCheck.data);
            formData.items = formData.items.split('\n').filter(item => item.trim().length);

            return formData;
        },
        onSubmit() {
            this.$refs.labelForm.validate((valid) => {
                if (valid) {
                    if (this.prepareLabelFormData()) {
                        this.form.loading = true;

                        this.axios.post(this.endpoints['user_label_creator'], this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.$router.push({
                                    path: "/ecommerce/user/label/creator"
                                });
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        prepareLabelFormData() {
            if (!(this.form.data.items.length > 0)) {
                this.$notify({
                    type: 'warning',
                    title: this.$t("messages.warning"),
                    message: this.$t("pages.ecommerce.user.label.creator.save.warnings.items")
                })

                return false;
            }

            return true;
        }
    }
}
</script>