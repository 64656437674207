<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <router-link to="/stream/provider" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
            </span>
            {{ $t("pages.stream.provider.title") }}
        </router-link>
    </div>

    <custom-table
        :title="sprintf($t('pages.stream.provider.account.titlePattern'), [streamProvider.name ?? ''])"
        :subTitle="$t('pages.stream.provider.account.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        selectableRows
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">

        <template v-slot:status="{ row: record }">
            <span :class="record.active ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.active ? $t('common.active') : $t('common.passive') }}</span>
        </template>

        <template v-slot:created_at="{ row: record }">
            {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
        </template>

        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <a v-on:click="fetchAccount(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                         <inline-svg src="/media/icons/duotune/art/art005.svg"/>
                    </span>
                </a>

                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>

    <div class="modal fade" id="kt_modal_add_account" ref="addAccountModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="accountForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.stream.provider.account.cols.title') }}</label>
                                <el-form-item prop="title" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.title" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('common.status') }}</label>
                                <el-form-item prop="status">
                                    <el-radio-group v-model="form.data.active">
                                        <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                        <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </div>

                            <template v-if="streamProvider.account_credentials_layout.length">
                                <div class="mb-10"></div>
                                <el-divider content-position="center">{{ $t('pages.stream.provider.account.credential.title') }}</el-divider>

                                <div class="fv-row mb-7" v-for="(credential, credentialIndex) in streamProvider.account_credentials_layout" :key="credentialIndex">
                                    <label class="fs-6 fw-bold mb-2">{{ credential.title }}</label>
                                    <el-form-item :prop="'credentials.' + credential.opt">
                                        <el-input v-model="form.data.credentials[credential.opt]" type="text" v-if="credential.type == 'input'"/>
                                    </el-form-item>
                                </div>
                            </template>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "index",
    components: {
        CustomTable
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("pages.stream.provider.account.cols.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.stream.provider.account.cols.title"),
                    key: "title"
                },
                {
                    name: this.$t("common.status"),
                    scopedSlots: {customRender: "status"}
                },
                {
                    name: this.$t('common.createdAt'),
                    scopedSlots: {customRender: "created_at"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {
                    credentials: {}
                }
            },
            selectedRowKeys: [],
            streamProvider: {
                account_credentials_layout: []
            }
        }
    },
    computed: {
        providerID() {
            return this.$route.params.id;
        },
        table() {
            return this.$store.state.stream.provider.account.table;
        }
    },
    created() {
        if (!(this.providerID && this.providerID > 0)) {
            this.$router.push({
                path: "/stream/provider"
            });
        }
    },
    mounted() {
        this.setCurrentPageBreadcrumbs(this.$t("pages.stream.provider.account.title"), [this.$t("menu.streamProvider")]);

        if (this.providerID && this.providerID > 0) {
            this.loadProvider();

            this.$store.dispatch('stream/provider/account/get', {
                page: {},
                filterData: {
                    provider_id: this.providerID,
                    sort: 'created_at:desc'
                }
            });
        }
    },
    methods: {
        loadProvider() {
            this.axios.get(this.endpoints['stream_provider'] + '/' + this.providerID).then((response) => {
                this.streamProvider = response.data.data;
            });
        },
        newAccount() {
            this.form.updateStatus = false;
            this.form.data = {
                provider_id: this.providerID,
                credentials: {},
                active: true
            };

            this.form.title = this.$t("pages.stream.provider.account.newAccount");
            this.showModal(this.$refs.addAccountModal);
        },
        fetchAccount(record) {
            this.form.updateStatus = true;
            this.form.title = this.$t("pages.stream.provider.account.editAccount");

            this.axios.get(this.endpoints['stream_provider_account'] + '/' + record.id).then(response => {
                let data = response.data.data;
                let credentials = data.credentials;

                if (data.account_credentials_layout && Array.isArray(data.account_credentials_layout)) {
                    this.streamProvider.account_credentials_layout = data.account_credentials_layout;
                }

                delete data.account_credentials_layout;

                data.credentials = {};

                if (credentials.length > 0) {
                    credentials.forEach((item) => {
                        data.credentials[item.opt] = item.val;
                    })
                }

                this.form.data = data;
                this.showModal(this.$refs.addAccountModal);
            });
        },
        onSubmit() {
            this.$refs.accountForm.validate((valid) => {
                if (valid) {
                    if (this.prepareAccountFormData()) {
                        this.form.loading = true;

                        if (this.form.data.id) {
                            this.axios.put(this.endpoints['stream_provider_account'] + '/' + this.form.data.id, this.form.data).then(response => {
                                this.onResponse(response.data, () => {
                                    this.refreshTable();
                                    this.hideModal(this.$refs.addAccountModal);
                                }, () => {
                                    this.form.loading = false;
                                });
                            }).catch(error => {
                                this.onResponseFailure(error.response.data, () => {
                                    this.form.loading = false;
                                });
                            });
                        } else {
                            this.axios.post(this.endpoints['stream_provider_account'], this.form.data).then(response => {
                                this.onResponse(response.data, () => {
                                    this.refreshTable();
                                    this.hideModal(this.$refs.addAccountModal);
                                }, () => {
                                    this.form.loading = false;
                                });
                            }).catch(error => {
                                this.onResponseFailure(error.response.data, () => {
                                    this.form.loading = false;
                                });
                            });
                        }
                    }
                } else {
                    return false;
                }
            });
        },
        deleteRecord(id) {
            this.$store.dispatch("stream/provider/account/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        prepareAccountFormData() {
            let formData = this.form.data;

            ['credentials'].forEach((key) => {
                let data = {};
                for (let index in formData[key]) {
                    if (formData[key][index].trim().length) {
                        data[index] = formData[key][index];
                    }
                }

                formData[key] = data;
            });

            return true;
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.newAccount();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        refreshTable() {
            this.$store.dispatch("stream/provider/account/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("stream/provider/account/get", {
                page: pagination,
                filterData: Object.assign({
                    provider_id: this.providerID,
                    sort: 'created_at:desc'
                }, filterData)
            });
        },
        handleSelectedRow(record) {
            this.selectedRowKeys = record;
        }
    }
}
</script>

<style scoped>

</style>