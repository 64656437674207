const DocMenuConfig = [
    {
        pages: [
            {
                heading: "dashboard",
                route: "/dashboard",
                svgIcon: "/media/icons/duotune/art/art002.svg",
                fontIcon: "bi-app-indicator",
            },
            {
                heading: "pages",
                route: "/page",
                svgIcon: "/media/icons/duotune/general/gen005.svg"
            },
            {
                sectionTitle: "statistic.title",
                route: "/statistic",
                svgIcon: "/media/icons/duotune/graphs/gra001.svg",
                fontIcon: "bi-chart",
                sub: [
                    {
                        heading: "statistic.general",
                        route: "/statistic/general",
                    },
                    {
                        heading: "statistic.student",
                        route: "/statistic/student",
                    },
                    {
                        heading: "statistic.lesson",
                        route: "/statistic/lesson",
                    },
                    {
                        heading: "statistic.product.title",
                        route: "/statistic/product",
                    },
                    {
                        heading: "statistic.product.specific",
                        route: "/statistic/product/specific",
                    }
                ]
            },
        ],
    },
    {
        heading: "ecommerceManagement",
        route: "/ecommerce",
        pages: [
            {
                sectionTitle: "productManagement",
                route: "/ecommerce/product-management",
                svgIcon: "/media/icons/duotune/ecommerce/ecm009.svg",
                fontIcon: "bi-grid",
                sub: [
                    {
                        heading: "categories",
                        route: "/ecommerce/product-management/category",
                    },
                    {
                        heading: "products",
                        route: "/ecommerce/product-management/product",
                    },
                    {
                        heading: "variants",
                        route: "/ecommerce/product-management/variant"
                    },
                ]
            },
            {
                sectionTitle: "orderManagement",
                route: "/ecommerce/order-management",
                svgIcon: "/media/icons/duotune/ecommerce/ecm001.svg",
                fontIcon: "bi-cart2",
                sub: [
                    {
                        heading: "orders",
                        route: "/ecommerce/order-management/order",
                    },
                    {
                        heading: "orderShipping",
                        route: "/ecommerce/order-management/shipping",
                    },
                    {
                        heading: "orderCreator",
                        route: "/ecommerce/order-management/order/creator",
                    },
                    {
                        heading: "orderChangeUsageMulti",
                        route: "/ecommerce/order-management/change-usage-multi",
                    },
                ]
            },
            {
                heading: "paymentMethods",
                route: "/ecommerce/payment-method",
                svgIcon: "/media/icons/duotune/finance/fin002.svg",
                fontIcon: "bi-credit-card",
            },
            {
                heading: "shippingMethods",
                route: "/ecommerce/shipping-method",
                svgIcon: "/media/icons/duotune/ecommerce/ecm006.svg",
                fontIcon: "bi-truck",
            },
            {
                sectionTitle: "userManagement",
                route: "/ecommerce/user",
                svgIcon: "/media/icons/duotune/communication/com006.svg",
                fontIcon: "bi-person",
                sub: [
                    {
                        heading: "users",
                        route: "/ecommerce/user",
                    },
                    {
                        heading: "userCreator",
                        route: "/ecommerce/user/creator",
                    },
                    {
                        heading: "user.label",
                        route: "/ecommerce/user/label",
                    },
                    {
                        heading: "userLabelCreator",
                        route: "/ecommerce/user/label/creator",
                    }
                ]
            },
            {
                sectionTitle: "discountCouponManagement.title",
                route: "/ecommerce/discount-coupon",
                svgIcon: "/media/icons/duotune/ecommerce/ecm003.svg",
                fontIcon: "bi-percent",
                sub: [
                    {
                        heading: "discountCouponManagement.coupons",
                        route: "/ecommerce/discount-coupon",
                    },
                    {
                        heading: "discountCouponManagement.creator",
                        route: "/ecommerce/discount-coupon/creator",
                    }
                ]
            },
            {
                sectionTitle: "invoiceManagement",
                route: "/ecommerce/invoice-management",
                svgIcon: "/media/icons/duotune/ecommerce/ecm008.svg",
                fontIcon: "bi-receipt",
                sub: [
                    {
                        heading: "invoices",
                        route: "/ecommerce/invoice-management/invoice"
                    },
                    {
                        heading: "invoiceProviders",
                        route: "/ecommerce/invoice-management/provider"
                    }
                ]
            },
        ]
    },
    {
        heading: "localizationManagement",
        route: "/localization",
        pages: [
            {
                heading: "taxes",
                route: "/localization/tax",
                svgIcon: "/media/icons/duotune/ecommerce/ecm011.svg",
                fontIcon: "bi-percent",
            }
        ]
    },
    {
        heading: "moduleManagement",
        route: "/module",
        pages: [
            {
                heading: "modules",
                route: "/module",
                svgIcon: "/media/icons/duotune/abstract/abs027.svg",
                fontIcon: "bi-layers",
            }
        ]
    },
    {
        heading: "mediaManagement",
        route: "/media",
        pages: [
            {
                heading: "media",
                route: "/media/management",
                svgIcon: "/media/icons/duotune/files/fil012.svg",
                fontIcon: "bi-folder-fill",
            },
            {
                heading: "streamProvider",
                route: "/stream/provider",
                svgIcon: "/media/icons/duotune/social/soc007.svg",
                fontIcon: "bi-video",
            }
        ]
    },
    {
        heading: "systemManagement",
        route: "/system",
        pages: [
            {
                heading: "admins",
                route: "/system/admin",
                svgIcon: "/media/icons/duotune/communication/com006.svg",
                fontIcon: "bi-person",
            },
            {
                heading: "variables",
                route: "/system/variable",
                svgIcon: "/media/icons/duotune/coding/cod003.svg",
                fontIcon: "bi-code-slash",
            },
            {
                heading: "systemSetting",
                route: "/system/setting",
                svgIcon: "/media/icons/duotune/coding/cod001.svg",
                fontIcon: "bi-gear",
            },
            {
                heading: "siteSetting",
                route: "/system/site-setting",
                svgIcon: "/media/icons/duotune/coding/cod001.svg",
                fontIcon: "bi-gear",
            },
            {
                sectionTitle: "notification.title",
                route: "/system/notification",
                svgIcon: "/media/icons/duotune/general/gen007.svg",
                fontIcon: "bi-bell",
                sub: [
                    {
                        heading: "notification.item.group",
                        route: "/system/notification/group"
                    }
                ]
            },
            {
                sectionTitle: "sms.title",
                route: "/system/sms/provider",
                svgIcon: "/media/icons/duotune/communication/com002.svg",
                fontIcon: "bi-envelope",
                sub: [
                    {
                        heading: "sms.provider",
                        route: "/system/sms/provider",
                    },
                    {
                        heading: "sms.multiSending",
                        route: "/system/sms/multi-send",
                    }
                ]
            },
            {
                heading: "linkManager",
                route: "/system/link-manager",
                svgIcon: "/media/icons/duotune/coding/cod007.svg",
                fontIcon: "bi-link-45deg",
            }
        ]
    }

    /*{
        heading: "craft",
        route: "/crafted",
        pages: [
            {
                sectionTitle: "pages",
                route: "/pages",
                svgIcon: "/media/icons/duotune/general/gen022.svg",
                fontIcon: "bi-archive",
                sub: [
                    {
                        sectionTitle: "profile",
                        route: "/profile",
                        sub: [
                            {
                                heading: "profileOverview",
                                route: "/crafted/pages/profile/overview",
                            },
                            {
                                heading: "projects",
                                route: "/crafted/pages/profile/projects",
                            },
                            {
                                heading: "campaigns",
                                route: "/crafted/pages/profile/campaigns",
                            },
                            {
                                heading: "documents",
                                route: "/crafted/pages/profile/documents",
                            },
                            {
                                heading: "connections",
                                route: "/crafted/pages/profile/connections",
                            },
                            {
                                heading: "activity",
                                route: "/crafted/pages/profile/activity",
                            },
                        ],
                    },
                    {
                        sectionTitle: "wizards",
                        route: "/wizard",
                        sub: [
                            {
                                heading: "horizontal",
                                route: "/crafted/pages/wizards/horizontal",
                            },
                            {
                                heading: "vertical",
                                route: "/crafted/pages/wizards/vertical",
                            },
                        ],
                    },
                ],
            },
            {
                sectionTitle: "account",
                route: "/account",
                svgIcon: "/media/icons/duotune/communication/com006.svg",
                fontIcon: "bi-person",
                sub: [
                    {
                        heading: "accountOverview",
                        route: "/crafted/account/overview",
                    },
                    {
                        heading: "settings",
                        route: "/crafted/account/settings",
                    },
                ],
            },
            {
                sectionTitle: "authentication",
                svgIcon: "/media/icons/duotune/technology/teh004.svg",
                fontIcon: "bi-sticky",
                sub: [
                    {
                        sectionTitle: "basicFlow",
                        sub: [
                            {
                                heading: "signIn",
                                route: "/sign-in",
                            },
                            {
                                heading: "signUp",
                                route: "/sign-up",
                            },
                            {
                                heading: "passwordReset",
                                route: "/password-reset",
                            },
                        ],
                    },
                    {
                        heading: "error404",
                        route: "/404",
                    },
                    {
                        heading: "error500",
                        route: "/500",
                    },
                ],
            },
            {
                sectionTitle: "modals",
                route: "/modals",
                svgIcon: "/media/icons/duotune/art/art009.svg",
                fontIcon: "bi-shield-check",
                sub: [
                    {
                        sectionTitle: "general",
                        route: "/general",
                        sub: [
                            {
                                heading: "inviteFriends",
                                route: "/crafted/modals/general/invite-friends",
                            },
                            {
                                heading: "viewUsers",
                                route: "/crafted/modals/general/view-user",
                            },
                            {
                                heading: "upgradePlan",
                                route: "/crafted/modals/general/upgrade-plan",
                            },
                            {
                                heading: "shareAndEarn",
                                route: "/crafted/modals/general/share-and-earn",
                            },
                        ],
                    },
                    {
                        sectionTitle: "forms",
                        route: "/forms",
                        sub: [
                            {
                                heading: "newTarget",
                                route: "/crafted/modals/forms/new-target",
                            },
                            {
                                heading: "newCard",
                                route: "/crafted/modals/forms/new-card",
                            },
                            {
                                heading: "newAddress",
                                route: "/crafted/modals/forms/new-address",
                            },
                            {
                                heading: "createAPIKey",
                                route: "/crafted/modals/forms/create-api-key",
                            },
                        ],
                    },
                    {
                        sectionTitle: "wizards",
                        route: "/wizards",
                        sub: [
                            {
                                heading: "twoFactorAuth",
                                route: "/crafted/modals/wizards/two-factor-auth",
                            },
                            {
                                heading: "createApp",
                                route: "/crafted/modals/wizards/create-app",
                            },
                            {
                                heading: "createAccount",
                                route: "/crafted/modals/wizards/create-account",
                            },
                        ],
                    },
                ],
            },
            {
                sectionTitle: "widgets",
                route: "/widgets",
                svgIcon: "/media/icons/duotune/general/gen025.svg",
                fontIcon: "bi-layers",
                sub: [
                    {
                        heading: "widgetsLists",
                        route: "/crafted/widgets/lists",
                    },
                    {
                        heading: "widgetsStatistics",
                        route: "/crafted/widgets/statistics",
                    },
                    {
                        heading: "widgetsCharts",
                        route: "/crafted/widgets/charts",
                    },
                    {
                        heading: "widgetsMixed",
                        route: "/crafted/widgets/mixed",
                    },
                    {
                        heading: "widgetsTables",
                        route: "/crafted/widgets/tables",
                    },
                    {
                        heading: "widgetsFeeds",
                        route: "/crafted/widgets/feeds",
                    },
                ],
            },
        ],
    },
    {
        heading: "apps",
        route: "/apps",
        pages: [
            {
                sectionTitle: "customers",
                route: "/customers",
                svgIcon: "/media/icons/duotune/finance/fin006.svg",
                fontIcon: "bi-printer",
                sub: [
                    {
                        heading: "gettingStarted",
                        route: "/apps/customers/getting-started",
                    },
                    {
                        heading: "customersListing",
                        route: "/apps/customers/customers-listing",
                    },
                    {
                        heading: "customerDetails",
                        route: "/apps/customers/customer-details",
                    },
                ],
            },
            {
                sectionTitle: "subscriptions",
                route: "/subscriptions",
                svgIcon: "/media/icons/duotune/ecommerce/ecm002.svg",
                fontIcon: "bi-cart",
                sub: [
                    {
                        heading: "getStarted",
                        route: "/subscriptions/getting-started",
                    },
                    {
                        heading: "subscriptionList",
                        route: "/subscriptions/subscription-list",
                    },
                    {
                        heading: "addSubscription",
                        route: "/subscriptions/add-subscription",
                    },
                    {
                        heading: "viewSubscription",
                        route: "/subscriptions/view-subscription",
                    },
                ],
            },
            {
                heading: "calendarApp",
                route: "/apps/calendar",
                svgIcon: "/media/icons/duotune/general/gen014.svg",
                fontIcon: "bi-calendar3-event",
            },
            {
                sectionTitle: "chat",
                route: "/chat",
                svgIcon: "/media/icons/duotune/communication/com012.svg",
                fontIcon: "bi-chat-left",
                sub: [
                    {
                        heading: "privateChat",
                        route: "/apps/chat/private-chat",
                    },
                    {
                        heading: "groupChat",
                        route: "/apps/chat/group-chat",
                    },
                    {
                        heading: "drawerChat",
                        route: "/apps/chat/drawer-chat",
                    },
                ],
            },
        ],
    },*/
];

export default DocMenuConfig;
